<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          :label="label"
          persistent-hint
          :prepend-icon="icons.mdiCalendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :class="customClass"
          :hint="hint"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        type="month"
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";
import { computed, onMounted, ref, watch } from "@vue/composition-api";

import { getQueryStringMonth } from "@/utils/dates";

export default {
  name: "DatePickerSingleMonth",
  props: {
    identifier: {
      type: String,
      default: "date-picker-single-month",
    },
    label: {
      type: String,
      default: "Mois",
    },
    dateInit: {
      type: String,
      default: () => {
        return "previous";
      },
      validator(value) {
        return [
          "null",
          "previous",
          "current",
          "yesterday",
          "n-month-ago",
        ].includes(value);
      },
    },
    dense: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: "Format MM/YYYY",
    },
    useGetParams: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onSelect"],
  setup(props, { emit }) {
    const date = ref("");
    const menu = ref(false);

    // =>  METHODS
    const formatDate = (_date) => {
      if (!_date) {
        return null;
      }

      const [year, month] = _date.split("-");

      return `${month}/${year}`;
    };

    // =>  COMPUTED
    const formattedDate = computed(() => formatDate(date.value));

    // =>  WATCH
    watch(
      () => date.value,
      () => {
        if (props.useGetParams) {
          const url = new URL(window.location.href);
          url.searchParams.set("month", date.value);
          window.history.replaceState(null, null, url.toString());
        }

        emit("onSelect", date.value, props.identifier);
      }
    );

    // =>  HOOKS
    onMounted(() => {
      if (props.useGetParams) {
        const monthDate = getQueryStringMonth(-3, 0);
        if (monthDate) {
          date.value = monthDate.toISOString().substring(0, 7);

          return;
        }
      }

      const d = new Date();
      if (
        props.dateInit == "previous" ||
        (props.dateInit == "yesterday" && d.getDate() === 1)
      ) {
        const _date = new Date(d.getFullYear(), d.getMonth() - 1, 2);

        _date.setHours(-5);

        date.value = new Date(_date - _date.getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 7);
      } else {
        date.value = new Date(d - d.getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 7);
      }
    });

    // =>  DATA
    return {
      date,
      formattedDate,
      menu,
      icons: {
        mdiCalendar,
      },
    };
  },
};
</script>

<style scoped>
/* 
[role="menu"] {
  top: 215px !important;
  right: 0px !important; 
}*/
</style>
